class BookindyUKStoreClient {
    /**
     * Fetches stores data
     *
     * @method fetchStoreData
     * @param {Object} userLocation The users geolocation
     * @return {Promise} A promise that is fulfilled with a store data
     */
    fetchStoreData(userLocation, maxDistance) {
        return new Promise((resolve, reject) => {
            const storeData = localStorage.getItem("Bookindy.rawStoreData?v=2");
            if (storeData) {
                try {
                    resolve(
                        this.getNearestStore(
                            JSON.parse(storeData),
                            userLocation,
                            maxDistance
                        )
                    );
                    return;
                } catch (e) {}
            }

            fetch(
                "https://spreadsheets.google.com/feeds/list/1w8qElqMYB9X63U-8zUzNNCdS3H0Ampyfu_1nsdYVygQ/default/public/values?alt=json"
            )
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    localStorage.setItem(
                        "Bookindy.rawStoreData?v=2",
                        JSON.stringify(json)
                    );
                    resolve(
                        this.getNearestStore(json, userLocation, maxDistance)
                    );
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    /**
     * Takes raw store data, formats and returns the nearset store
     *
     * @method mapStoreData
     * @param {Object} storeData Raw store data
     * @param {Object} userLocation The users geolocation
     * @return {Promise} A promise that is fulfilled with the nearest store
     */
    getNearestStore(storeData, userLocation, maxDistance) {
        var nearestStore;
        var transformedStore;
        var data = storeData.feed.entry;

        for (var i = 0, len = data.length; i < len; i++) {
            var storeURL = `http://track.webgains.com/click.html?wgcampaignid=179695&wgprogramid=10671&wgtarget=${data[i].gsx$bookshophivepage.$t}`;

            transformedStore = {
                name: data[i].title.$t,
                lat: data[i].gsx$latitude.$t,
                long: data[i].gsx$longitude.$t,
                url: storeURL
            };

            transformedStore._distance = this.getDistanceFromLatLonInKm(
                userLocation.coords.longitude,
                userLocation.coords.latitude,
                transformedStore.long,
                transformedStore.lat
            );

            transformedStore.distance = transformedStore._distance.toFixed(1);

            if (maxDistance) {
                if (
                    (!nearestStore &&
                        transformedStore._distance < maxDistance) ||
                    (nearestStore &&
                        transformedStore._distance < nearestStore._distance)
                ) {
                    nearestStore = transformedStore;
                }
            } else {
                if (
                    !nearestStore ||
                    transformedStore._distance < nearestStore._distance
                ) {
                    nearestStore = transformedStore;
                }
            }
        }

        return nearestStore;
    }

    // Taken from http://stackoverflow.com/questions/21279559/geolocation-closest-locationlat-long-from-my-position
    // Modified to return distance in miles
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 3959; // Radius of the earth in miles
        var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        var dLon = this.deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) *
                Math.cos(this.deg2rad(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in miles
        return d;
    }

    deg2rad(deg) {
        return deg * (Math.PI / 180);
    }

    /**
     * Get the users geolocation
     *
     * @method getUserLocation
     * @return {Promise} A promise that is fulfilled with the users location
     */
    getUserLocation() {
        return new Promise(function(resolve, reject) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    resolve(position);
                },
                function(positionError) {
                    reject(positionError);
                },
                { maximumAge: 5 * 60 * 1000 }
            ); // 5 minutes
        });
    }

    /**
     * Locate the nearest store to user
     *
     * @method locateStore
     * @return {Promise} A promise that is fulfilled with a store object
     */
    locateStore(options) {
        return this.getUserLocation().then(userLocation => {
            return this.fetchStoreData(
                userLocation,
                options && options.maxDistance
            ).then(store => {
                return store;
            });
        });
    }
}

const client = new BookindyUKStoreClient();

client.locateStore({ maxDistance: 50 }).then(function(store) {
    if (store) {
        var el = document.getElementById("Header-body");
        el.innerHTML = `Browse Amazon, buy from <a href="${store.url}">${store.name} (${store.distance} miles away)</a>.`;
    }
});
